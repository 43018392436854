import React from 'react';

import Game from '../classes/Game';

import AnniversaryQuestion from './AnniversaryQuestion';
import AnniversaryAnswer from './AnniversaryAnswer';

import './Anniversary.css';

const HIDE_ANNIVERSARY_TIMEOUT = 5000;

export default class Anniversary extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            game: new Game(),
            currentQuestion: null,
            hasUnansweredQuestions: true,
        };

        this.testAnswer = this.testAnswer.bind(this);
        this.updateCurrentQuestion = this.updateCurrentQuestion.bind(this);
    }

    testAnswer(answer) {
        const { currentQuestion, game } = this.state;
        return game.testAnswer(currentQuestion.id, answer);
    }

    updateCurrentQuestion() {
        this.setState({
            hasUnansweredQuestions: this.state.game.hasUnansweredQuestions(),
            currentQuestion: this.state.game.getRandomUnansweredQuestion(),
        });
    }

    componentDidMount() {
        this.state.game.fetchQuestions().then(() => {
            this.updateCurrentQuestion();
        });
    }

    componentDidUpdate() {
        const { hideAnniversary } = this.props;
        const { hasUnansweredQuestions } = this.state;

        if (!hasUnansweredQuestions) {
            setTimeout(() => {
                hideAnniversary();
            }, HIDE_ANNIVERSARY_TIMEOUT);
        }
    }

    renderNoUnansweredQuestions() {
        return <div className="anniversary_all-questions-answered">
            Aktuell hast du alle Fragen beantwortet. Komm' später nochmal vorbei.
        </div>;
    }

    render() {
        const { currentQuestion, hasUnansweredQuestions } = this.state;

        if (!hasUnansweredQuestions) {
            return this.renderNoUnansweredQuestions();
        }

        return <>
            <AnniversaryQuestion question={currentQuestion} />
            <AnniversaryAnswer
                testAnswer={this.testAnswer}
                updateQuestion={this.updateCurrentQuestion}
            />
        </>;
    }
}
