import React from 'react';

import heart from '../static/heart.png';
import './Heart.css';

export default class Heart extends React.PureComponent {
    render() {
        return <div className="heart-container" onDoubleClick={this.props.onUnlockSecret}>
            <img src={heart} alt="Heart" className="heart"/>
        </div>;
    }
}
