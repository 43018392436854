import React from 'react';

import './Postcard.css';

export default class Postcard extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            additionalClassname: `postcard--${props.visibilityStatus}`,
            renderContent: false,
        };
    }

    componentDidUpdate(prevProps) {
        /**
         * This `componentDidUpdate` contains some performance hacks.
         * The whole postcard is only rendered to the DOM just before it is first shown.
         *
         * This way the background image is only loaded if the user wants to see it.
         */
        const { renderContent } = this.state;
        const { visibilityStatus } = this.props;

        if (!renderContent && visibilityStatus === 'visible' && prevProps.visibilityStatus === 'hidden') {
            this.setState({ renderContent: true });
        }

        if (renderContent) {
            // setTimeout ensures that the DOM is rendered first and the className is updated
            // in an additional render
            setTimeout(() => {
                this.setState({ additionalClassname: `postcard--${visibilityStatus}` });
            }, 50);
        }
    }

    render() {
        if (!this.state.renderContent) {
            return null;
        }

        return <div className={`postcard ${this.state.additionalClassname}`}>
            <div className="postcard__text">
                Huhu Mausi,<br />
                auf dem Platz auf der Karte war ich heut. Ich glaube, bis diese Karte hier
                bei dir ist, bin ich schon lange wieder da ❤️<br />
                <br />
                Ich freu mich auf dich, wir sehen uns bald wieder 🥰<br />
                <br />
                Ich liebe dich,<br />
                Jannis
            </div>
        </div>;
    }
}
