import React from 'react';

import './AnniversaryQuestion.css';

export default function AnniversaryQuestion(props) {
    if (props.question === null) {
        return <div className="anniversary-question__loading"/>;
    }
    return <div className="anniversary-question">
        {props.question.question}
    </div>;
}
