import React, { useState, useEffect, useContext } from 'react';

import { ContainerClassNameContext } from '../context';

import './AnniversaryAnswer.css';

// const DEBOUNCE_DURATION = 500;
const WAIT_AFTER_CORRECT_ANSWER = 4000;

function onChange(event, setAnswerGiven) {
    const answerGiven = event.target.value;
    setAnswerGiven(answerGiven);
}

// FIXME implement input debounce

export default function AnniversaryAnswer({ testAnswer, updateQuestion }) {
    const [answerGiven, setAnswerGiven] = useState('');
    const [answerCorrect, setAnswerCorrect] = useState(null);
    const setContainerClassName = useContext(ContainerClassNameContext);

    useEffect(() => {
        if (answerGiven === '') {
            setAnswerCorrect(null);
            return;
        }

        testAnswer(answerGiven).then(isAnswerCorrect => {
            setAnswerCorrect(isAnswerCorrect);
            if (isAnswerCorrect) {
                setTimeout(() => {
                    setAnswerGiven('');
                    updateQuestion();
                }, WAIT_AFTER_CORRECT_ANSWER);
            }
        });
    }, [answerGiven, testAnswer, updateQuestion]);

    useEffect(() => {
        let className = 'anniversary_answer ';
        if (answerCorrect === null) {
            className += 'anniversary_answer--none';
        } else if (answerCorrect === true) {
            className += 'anniversary_answer--correct';
        } else {
            className += 'anniversary_answer--incorrect';
        }
        setContainerClassName(className);
    }, [answerCorrect, setContainerClassName]);

    return <input
        autoFocus
        type="text"
        className="anniversary-answer"
        placeholder="Antwort..."
        value={answerGiven}
        onChange={event => onChange(event, setAnswerGiven)}
    />;
}
