import React from 'react';

import Heart from './Heart';
import Time from './Time';

const BEGIN_TIME = new Date(2019, 0, 22, 16, 45);
const FIRST_DECEMBER = new Date(2019, 11, 1);

export default function HeartWithTime(props) {
    const current = new Date();
    const baseline = current < FIRST_DECEMBER ? FIRST_DECEMBER : BEGIN_TIME;
    return <>
        <Heart {...props} />
        <Time calculationBaseline={baseline} />
    </>;
};
