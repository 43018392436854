import React, { useState, useEffect } from 'react';

import HeartWithTime from './HeartWithTime';
import Postcard from './Postcard';
import Anniversary from './Anniversary';

import { ContainerClassNameContext } from '../context';

import './App.css';

const RANDOM_TIMEINTERVAL_STORE_KEY = 'random_future_timeinterval_start';
const INTERVAL_LENGTH_HOURS = 10;
const MAX_DAYS_IN_FUTURE = 3;

function checkAnniversary() {
    const date = new Date();

    return date.getDay() === 22 || isAnniversaryWithoutDate();
}

function isAnniversaryWithoutDate() {
    const interval = localStorage.getItem(RANDOM_TIMEINTERVAL_STORE_KEY);
    if (interval === null) {
        setRandomTimeInterval();
        return false;
    }
    const intervalStart = new Date(interval);
    const now = new Date();
    const hoursDiff = Math.abs((now.getTime() - intervalStart.getTime()) / 1000 * 60 * 60);
    const result = now.getTime() > intervalStart.getTime() && hoursDiff < INTERVAL_LENGTH_HOURS;

    if (now.getTime() > intervalStart.getTime() && hoursDiff > INTERVAL_LENGTH_HOURS) {
        setRandomTimeInterval();
    }

    return result;
}

function setRandomTimeInterval() {
    const now = new Date();

    const days = Math.random() * MAX_DAYS_IN_FUTURE;

    const newDate = new Date(now.getTime() + days * 1000 * 60 * 60 * 24);

    localStorage.setItem(RANDOM_TIMEINTERVAL_STORE_KEY, newDate.toJSON());
}

export default function App() {
    const [showPostcard, setShowPostcard] = useState(false);
    const [isAnniversary, setAnniversary] = useState(false);
    const [hideAnniversary, setHideAnniversary] = useState(false);
    const [containerClassName, setContainerClassName] = useState('');

    function makePostcardVisibleTemporairly() {
        setShowPostcard(true);
        setTimeout(() => setShowPostcard(false), 30000);
    }

    useEffect(() => {
        setAnniversary(checkAnniversary());
        const intervalId = setInterval(() => {
            setAnniversary(checkAnniversary());
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    let contentRenderer = null;
    if (isAnniversary && !hideAnniversary) {
        contentRenderer = () => <Anniversary
            hideAnniversary={() => setHideAnniversary(true)}
        />;
    } else {
        contentRenderer = () => {
            return <HeartWithTime
                onUnlockSecret={makePostcardVisibleTemporairly}
            />;
        }
    }

    const visibilityStatus = showPostcard ? 'visible' : 'hidden';
    return (
        <ContainerClassNameContext.Provider value={setContainerClassName}>
            <div className={'container ' + containerClassName}>
                {contentRenderer()}
                <Postcard
                    visibilityStatus={visibilityStatus}
                />
            </div>
        </ContainerClassNameContext.Provider>
    );
}
