import { getJson, hashString } from '../helpers';

const QUESTIONS_URL = '/api/game/questions.json';
const ANSWERS_URL = '/api/game/answers/{hash}.json';

const STORAGE_KEY = 'answer_{id}';

const NOOP = () => { };

export default class Game {
    constructor(errorCallback) {
        this.gameStartData = null;
        this.questions = null;

        this.errorCallback = errorCallback || NOOP;
    }

    async fetchQuestions() {
        try {
            const questions = await getJson(QUESTIONS_URL);
            this.questions = questions;
            return this.questions;
        } catch (error) {
            this.errorCallback(error);
            return null;
        }
    }

    getUnansweredQuestions() {
        // FIXME save and filter the questions that are already answered
        return this.questions.filter(q => !this.hasAnswer(q.id));
    }

    hasUnansweredQuestions() {
        return this.getUnansweredQuestions().length !== 0;
    }

    getRandomUnansweredQuestion() {
        const questions = this.getUnansweredQuestions();

        if (questions.length === 0) {
            return null;
        }

        const randomIndex = Math.floor(Math.random() * questions.length);
        return questions[randomIndex];
    }

    async testAnswer(questionId, answerText) {
        const hash = hashString(answerText);
        const url = ANSWERS_URL.replace(/{hash}/, hash);

        try {
            const data = await getJson(url);
            const result = data.id === questionId && data.answer === answerText;
            if (result) {
                this.saveAnswer(questionId, answerText);
            }
            return result;
        } catch {
            return false;
        }
    }

    hasAnswer(id) {
        return localStorage.getItem(this.__getKey(id)) !== null;
    }

    saveAnswer(id, answer) {
        localStorage.setItem(this.__getKey(id), answer);
    }

    __getKey(id) {
        return STORAGE_KEY.replace(/{id}/, id);
    }
}
