import React from 'react';

const INTERVAL = 1000;

const SECONDS = 1000;
const MINUTES = SECONDS * 60;
const HOURS = MINUTES * 60;
const DAYS = HOURS * 24;

function f(n) {
    if (n < 10) {
        return `0${n}`;
    }
    return String(n);
}

export default class Time extends React.PureComponent {
    constructor(props) {
        super(props);

        this.calculateTime = this.calculateTime.bind(this);

        this.intervalId = null;

        this.state = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    }

    componentDidMount() {
        this.intervalId = setInterval(this.calculateTime, INTERVAL);
        this.calculateTime();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    calculateTime() {
        const { calculationBaseline } = this.props;
        const currentTime = new Date().getTime();
        let timeSpan = Math.abs(currentTime - calculationBaseline.getTime());

        const days = Math.floor(timeSpan / DAYS);
        timeSpan -= days * DAYS;
        const hours = Math.floor(timeSpan / HOURS);
        timeSpan -= hours * HOURS;
        const minutes = Math.floor(timeSpan / MINUTES);
        timeSpan -= minutes * MINUTES;
        const seconds = Math.floor(timeSpan / SECONDS);

        this.setState({
            days, hours, minutes, seconds,
        });
    }

    render() {
        const { days, hours, minutes, seconds } = this.state;
        return `${f(days)} : ${f(hours)} : ${f(minutes)} : ${f(seconds)}`;
    }
}
